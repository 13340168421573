const appConfig = {
  "name": "spaces-admin",
  "version": "1.0.0",
  "stage": "release",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-test.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_nY154N4yV",
        "userPoolWebClientId": "3gbrc0j20mb59fbirm2i70ha8g",
        "oauth": {
          "domain": "atlas-auth-test.mit.edu",
          "scope": [
            "profile",
            "openid",
            "digital-id/user",
            "digital-id/search",
            "spaces/admin",
            "user/all",
            "feature/user"
          ],
          "redirectSignIn": "https://spaces-admin-test.mit.edu",
          "redirectSignOut": "https://spaces-admin-test.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-test.mit.edu",
        "paths": {
          "digital-id": "digital-id-v1",
          "spaces": "spaces-v1",
          "spacesV2": "spaces-v2",
          "scheduling": "scheduling-v1",
          "features": "feature-v1"
        }
      }
    },
    {
      "name": "us-west-1",
      "isPrimaryRegion": false,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-test.mit.edu",
      "amplify": {
        "userPoolId": "us-west-1_KRL6VdoBU",
        "userPoolWebClientId": "17gdm0d45ledpujifcocddtt21",
        "oauth": {
          "domain": "atlas-auth-us-west-test.mit.edu",
          "scope": [
            "profile",
            "openid",
            "digital-id/user",
            "digital-id/search",
            "spaces/admin",
            "user/all",
            "feature/user"
          ],
          "redirectSignIn": "https://spaces-admin-test.mit.edu",
          "redirectSignOut": "https://spaces-admin-test.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-us-west-test.mit.edu",
        "paths": {
          "digital-id": "digital-id-v1",
          "spaces": "spaces-v1",
          "spacesV2": "spaces-v2",
          "scheduling": "scheduling-v1",
          "features": "feature-v1"
        }
      }
    }
  ]
};
export default appConfig;